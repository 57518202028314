.popular-destinations-section {
    background-image: url('../../../public/svg/home/popular-destinations/top-line.svg');
    background-repeat: no-repeat;
    background-position: right 20% top 60px;
    .popular-destinations-container {
        background-image: url('../../../public/svg/home/popular-destinations/tree.svg');
        background-position: left 30% top 14px;
        background-repeat: no-repeat;
        @media only screen and (max-width: 1535px) {
            background-position: left 25% top 14px;
        }
        @media only screen and (max-width: 1279px) {
            background-position: left 12% top 14px;
        }
        @media only screen and (max-width: 1023px) {
            background-position: left 10% top 14px;
        }
        @media only screen and (max-width: 767px) {
            background-image: initial;
        }
    }
    figure {
        position: relative;

        .b-r-14{
            border-radius: 14px;
        }
        .redirect,
        figcaption {
            position: absolute;
        }
        .redirect {
            right: 0;
            top: 0;
            opacity: 0;
        }
        figcaption {
            bottom: 16px;
            left: 16px;
            right: 16px;
            background: url('../../../public/svg/common/brush.svg') no-repeat center;
            min-height: 80px;
            color: white;
            font-size: 2.375rem;
            @media only screen and (max-width: 1023px) {
                font-size: 1.5rem;
                font-weight: normal;
                min-width: 120px;
                max-width: 120px;
                background-size: contain;
            }
        }
    }
    span {
        color: #3D4152;
    }
    .destinations-swiper {
        a {
            width: 100%;
            figure {
                .picture {
                    width: 100%;
                }
            }
            &:hover {
                .redirect {
                    right: 12px;
                    top: 12px;
                    opacity: 1;
                }
            }
        }
    }
    @media only screen and (max-width: 1535px) {
        background-position: right 15% top 60px;
    }
    @media only screen and (max-width: 767px) {
        background-image: initial;
    }
}